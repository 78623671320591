import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {

  /********************
      General
  ********************/

  appversion: string = '2.0.10';
  apptitle: string = ' | MerchUp Pernod Ricard France';


  /********************
      Configuration
  ********************/

  // Élements spiritueux
  elements_spirit_default = [10, 10];
  elements_spirit_min = 1;
  elements_spirit_max = 20;

  // Étagères
  elements_shelve_default = [5, 5];
  elements_shelve_min = 3;
  elements_shelve_max = 6;

  // Élements autres
  elements_other_default = [0, 0];
  elements_other_min = 0;
  elements_other_max = 5;

}