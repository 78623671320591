import { AuthenticationService } from './services/authentication.service';
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker/';
import { ConfigService } from './services/config.service';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {


  constructor(
    public config: ConfigService,
    private swUpdate: SwUpdate,
  ) {
    this.initializeApp();
  }

  getDatas() {
  }

  initializeApp() {
  }

  ngOnInit() {

    // Redirection vers https
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    // Fenetre modale de mise à jour de l'app
    if (this.swUpdate) {
      this.swUpdate.available.subscribe((event) => {
        console.log('current', event.current, 'available', event.available);
        if (confirm('Une nouvelle version est disponible. Souhaitez-vous mettre à jour l\'app ?')) {
          window.location.reload();
        }
      });
    }

  }

}
